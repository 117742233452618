import { ReactComponent as IaForBrands } from "@/assets/img/ia-for-brands.svg";

import LoginForm from "@/components/LoginForm/LoginForm";

import theme from "@/branding/theme";

import "./Login.css";

const Login = () => (
  <div className="login-content">
    <div className="left-side">
      <div className="left-side-content">
        <img
          alt=""
          className="logo-login"
          src={theme().login.loginLog.default}
          style={{ maxHeight: "4rem" }}
        />
        {theme().login.case === "Mahou" ? (
          <h1 className="login-title">
            MAHOU <span className="sub-title-login">SAN</span>MIGUEL
          </h1>
        ) : (
          <h1 className="login-title">{theme().login.loginTitle}</h1>
        )}

        <p
          className={`${theme().login.case === "Mahou" ? "login-mahou " : "login-alternative"}`}>
          Por favor, introduce tus credenciales de acceso.
        </p>
        <LoginForm />
      </div>
    </div>
    <div
      className="right-side"
      onClick={() =>
        window.open("https://superreal.io/gen-ia-4-marketing", "_blank")
      }
      role="button"
      style={{
        padding: "0.5em 0 0.5em 0",
        backgroundImage: `url(${theme().login.loginImage})`,
        backgroundSize: theme().login.loginImage ? "cover" : undefined,
        backgroundPosition: theme().login.loginImage ? "center" : undefined,
      }}>
      <div className="right-side-ai">
        <IaForBrands
          alt=""
          color={theme().login.company === "Fundador" ? "white" : undefined}
          height="30"
        />
      </div>
    </div>
  </div>
);

export default Login;
