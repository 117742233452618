import { ErrorMessage, Form, Formik } from "formik";
import { Button, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import { useEffect, useState } from "react";

import emailIcon from "@/assets/img/email-icon.svg";
import requestSendedImage from "@/assets/img/request-sended.svg";

import theme from "@/branding/theme";
import { useRestorePassowrdMutation } from "@/service/user";

import "../UpdatePasswordModal/UpdatePasswordModal.css";
import "./RememberPasswordModal.css";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Introduce un correo electrónico válido")
    .required("Este campo es requerido"),
});

const RememberPasswordModal = ({ show, handleClose }) => {
  const [succesModalIsOpen, setSuccessModalIsOpen] = useState(false);

  const [restorePassword, { isSuccess, isLoading, reset }] =
    useRestorePassowrdMutation();

  const handleSubmit = (values) => {
    const url = window.location.href;
    restorePassword({ email: values.email, url });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setSuccessModalIsOpen(true);
      reset();
    }
  }, [handleClose, isSuccess, reset]);

  return (
    <>
      <Modal
        centered
        onHide={() => {
          handleClose();
        }}
        show={show}
        size="lg">
        <Modal.Header bsPrefix="custom-modal-header" closeButton>
          <div />
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={validationSchema}>
            {({ values, handleChange, errors, touched, handleBlur }) => (
              <Form>
                <div className="form-group">
                  <h4 className="remember-title">
                    Introduce tu correo electrónico
                  </h4>
                  <p className="remember-alternative">
                    Utiliza el correo electrónico corporativo con el que fuiste
                    invitado a {theme().login.company} GPT.
                  </p>
                  <div className="label-container">
                    <img alt="email logo" src={emailIcon} />
                    <p
                      className="label"
                      style={{
                        color:
                          errors.email && touched.email ? "red" : undefined,
                      }}>
                      Email
                    </p>
                  </div>
                  <input
                    className="input-login"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Introduce tu email"
                    style={{
                      borderColor:
                        errors.email && touched.email ? "red" : undefined,
                    }}
                    type="email"
                    value={values.email}
                  />
                  <ErrorMessage
                    className="error-message"
                    component="p"
                    name="email"
                  />
                </div>
                <Button
                  className="confirm-button"
                  disabled={false}
                  type="submit">
                  Enviar {isLoading ? <Spinner size="sm" /> : null}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        onHide={() => setSuccessModalIsOpen(false)}
        show={succesModalIsOpen}
        size="lg">
        <Modal.Header bsPrefix="custom-modal-header" closeButton>
          <div />
        </Modal.Header>
        <Modal.Body>
          <div className="success-modal">
            <img alt="success" src={requestSendedImage} />
            <h4>¡Gracias! Hemos recibido tu solicitud.</h4>
            <p>
              Si tu correo está habilitado como usuario de{" "}
              {theme().login.company}, recibirás una nueva contraseña en tu
              correo en un plazo máximo de 24 horas.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RememberPasswordModal;
