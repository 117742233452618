import { Navigate } from "react-router-dom";

import { getLocalStorage } from "@/utils/localstorage";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = () => {
    const token = getLocalStorage("token");

    if (!token) {
      return false;
    }

    return true;
  };

  if (!isAuthenticated()) {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return children;
};
export default ProtectedRoute;
