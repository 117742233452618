// Alhambra
import supremo12 from "@/assets/img/fundador/products/Supremo_12.png";
import supremo15 from "@/assets/img/fundador/products/Supremo_15.png";
import supremo18 from "@/assets/img/fundador/products/Supremo_18.png";
import tripleMadera from "@/assets/img/fundador/products/Triple_Madera.png";
import dobleMadera from "@/assets/img/fundador/products/doble-madera.png";
import sherry from "@/assets/img/fundador/products/sherry.png";

import { FUNDADOR_MODELS } from "@/utils";

export const fundadorProductsNames = {
  FUNDADOR_SUPREMO_12: "Fundador Supremo 12",
  FUNDADOR_SUPREMO_15: "Fundador Supremo 15",
  FUNDADOR_SUPREMO_18: "Fundador Supremo 18",
  SHERRY_CASK_SOLERA: "Sherry Cask Solera",
  FUNDADOR_DOBLE_MADERA: "Fundador Doble Madera",
  FUNDADOR_TRIPLE_MADERA: "Fundador Triple Madera",
};

const getFundadorProductsByProfile = (profile) => {
  const personajesProducts = [
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_SUPREMO_12,
      name: fundadorProductsNames.FUNDADOR_SUPREMO_12,
      image: supremo12,
      backgroundColor: "#861726",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_SUPREMO_15,
      name: fundadorProductsNames.FUNDADOR_SUPREMO_15,
      image: supremo15,
      backgroundColor: "#253371",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_SUPREMO_18,
      name: fundadorProductsNames.FUNDADOR_SUPREMO_18,
      image: supremo18,
      backgroundColor: "#D9BF9E",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.SHERRY_CASK_SOLERA,
      name: fundadorProductsNames.SHERRY_CASK_SOLERA,
      image: sherry,
      backgroundColor: "#C21A1C",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_DOBLE_MADERA,
      name: fundadorProductsNames.FUNDADOR_DOBLE_MADERA,
      image: dobleMadera,
      backgroundColor: "#38539F",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_TRIPLE_MADERA,
      name: fundadorProductsNames.FUNDADOR_TRIPLE_MADERA,
      image: tripleMadera,
      backgroundColor: "#000",
    },
  ];

  const momentosProducts = [
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_SUPREMO_12,
      name: fundadorProductsNames.FUNDADOR_SUPREMO_12,
      image: supremo12,
      backgroundColor: "#861726",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_SUPREMO_15,
      name: fundadorProductsNames.FUNDADOR_SUPREMO_15,
      image: supremo15,
      backgroundColor: "#253371",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_SUPREMO_18,
      name: fundadorProductsNames.FUNDADOR_SUPREMO_18,
      image: supremo18,
      backgroundColor: "#D9BF9E",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.SHERRY_CASK_SOLERA,
      name: fundadorProductsNames.SHERRY_CASK_SOLERA,
      image: sherry,
      backgroundColor: "#C21A1C",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_DOBLE_MADERA,
      name: fundadorProductsNames.FUNDADOR_DOBLE_MADERA,
      image: dobleMadera,
      backgroundColor: "#38539F",
    },
    {
      aux: undefined,
      fileName: fundadorProductsNames.FUNDADOR_TRIPLE_MADERA,
      name: fundadorProductsNames.FUNDADOR_TRIPLE_MADERA,
      image: tripleMadera,
      backgroundColor: "#000000",
    },
  ];

  switch (profile) {
    case FUNDADOR_MODELS.PERSONAJES:
      return personajesProducts;
    case FUNDADOR_MODELS.MOMENTOS:
      return momentosProducts;
    default:
      return [];
  }
};

export default getFundadorProductsByProfile;
