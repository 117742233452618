import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import silboLoadingLogo from "@/assets/img/silbo_loading_logo.gif";

import { useInterval } from "@/hooks/useInterval";

import { getLoadingOverlayTextList } from "@/utils";

import "./LoadingOverlay.css";

export const LoadingOverlay = () => {
  const [overlayTextList, setOverlayTextList] = useState([]);
  const [transitionStart, setTransitionStart] = useState(false);
  const [overlayTextIdx, setOverlayTextIdx] = useState(0);
  const secs_per_sentence = 10;
  const profile = useSelector((state) => state.main.profile);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    setTransitionStart(true);
    const shuffledList = shuffle(getLoadingOverlayTextList(profile));
    setOverlayTextList(shuffledList);
  }, [profile]);

  useInterval(() => {
    setOverlayTextIdx((prevIdx) => (prevIdx + 1) % overlayTextList.length);
  }, secs_per_sentence * 1000);

  return (
    <div className={`silbo-loading-overlay ${transitionStart ? "show" : ""}`}>
      <div className="container d-flex align-items-center w-100 h-100 flex-column">
        <div className="text-center content" style={{ minWidth: "50%" }}>
          <img alt="" src={silboLoadingLogo} />

          {/* Carousel */}
          <div style={{ overflow: "hidden", width: 0, minWidth: "100%" }}>
            <div
              className="d-flex carousel"
              style={{ transform: `translate(-${overlayTextIdx * 100}%)` }}>
              {overlayTextList.map((item, idx) => (
                <div className="w-100" key={idx} style={{ minWidth: "100%" }}>
                  {item}
                </div>
              ))}
              {overlayTextList.map((item, idx) => (
                <div className="w-100" key={idx} style={{ minWidth: "100%" }}>
                  {item}
                </div>
              ))}
              {overlayTextList.map((item, idx) => (
                <div className="w-100" key={idx} style={{ minWidth: "100%" }}>
                  {item}
                </div>
              ))}
              {overlayTextList.map((item, idx) => (
                <div className="w-100" key={idx} style={{ minWidth: "100%" }}>
                  {item}
                </div>
              ))}
              {overlayTextList.map((item, idx) => (
                <div className="w-100" key={idx} style={{ minWidth: "100%" }}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingOverlay;
