import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
// Toastify
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Suspense, lazy, useEffect } from "react";

import { Configuration } from "@/components/Configuration";
import FallbackLoading from "@/components/FallbackLoading";
import { Header } from "@/components/Header/Header";
import LoadingOverlay from "@/components/Loadings/LoadingOverlay";
import LoadingOverlayV2 from "@/components/Loadings/LoadingOverlayV2";
import LowWidthPopup from "@/components/LowWidthPopup/LowWidthPopup";
import ProtectedRoute from "@/components/ProtectedRoute";

// Font awesome
import useDocumentHeight from "@/hooks/useDocumentHeight";

import SecuritasExtraRoutes from "@/pages/ExtraRoutesByClient/Securitas/Securitas";
// import Home from "@/pages/Home/Home";
import Login from "@/pages/Login/Login";

// import SilboAdCreatorAssistant from "@/pages/SilboAdCreatorAssistant/SilboAdCreatorAssistant";
// import  SilboImageAssistant  from "@/pages/SilboImageAssistant/SilboImageAssistant";
// import  SilboTextAssistant  from "@/pages/SilboTextAssistant/SilboTextAssistant";
import { setFakeMode, setIntervalPeriodForTask } from "@/redux/store";

import { applyBrandingTheme } from "@/branding/theme";

// Styles
import "./App.css";

const Home = lazy(() => import("@/pages/Home/Home"));
const SilboAdCreatorAssistant = lazy(
  () => import("@/pages/SilboAdCreatorAssistant/SilboAdCreatorAssistant"),
);
const SilboImageAssistant = lazy(
  () => import("@/pages/SilboImageAssistant/SilboImageAssistant"),
);
const SilboTextAssistant = lazy(
  () => import("@/pages/SilboTextAssistant/SilboTextAssistant"),
);
const MahouExtraRoutes = lazy(
  () => import("@/pages/ExtraRoutesByClient/Mahou/Mahou"),
);
const FundadorExtraRoutes = lazy(
  () => import("@/pages/ExtraRoutesByClient/Fundador/Fundador"),
);

// IMPORTANT, CHANGE FOR PROD DEPLOY
// axios.defaults.baseURL = 'https://silbo-gpt-api.azurewebsites.net';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const INTERVAL_PERIOD = 10 * 1000;
const FAKE_MODE = false;

const App = () => {
  applyBrandingTheme();

  const { t } = useTranslation();
  // const [ing, setLoading] = useState(false);
  const loading = useSelector((state) => state.main.loading);
  const loadingV2 = useSelector((state) => state.main.loadingV2);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFakeMode(FAKE_MODE));
    dispatch(setIntervalPeriodForTask(INTERVAL_PERIOD));

    // setTimeout( () => toast.error('Request has failed'))
  }, [dispatch]);

  const height = useDocumentHeight();
  return (
    <main className="main" style={{ height: height }}>
      <Header />

      <section className="flex-grow-1" style={{ position: "relative" }}>
        {loading ? <LoadingOverlay /> : null}
        {loadingV2 ? <LoadingOverlayV2 /> : null}

        <div className="silbo-profile-container">
          <Configuration />
        </div>

        <Routes>
          <Route
            element={
              <ProtectedRoute>
                <Suspense fallback={<FallbackLoading />}>
                  <Home />
                </Suspense>
              </ProtectedRoute>
            }
            path="/"
          />
          <Route
            element={
              <ProtectedRoute>
                <Suspense fallback={<FallbackLoading />}>
                  <SilboImageAssistant />
                </Suspense>
              </ProtectedRoute>
            }
            path="/images"
          />
          <Route
            element={
              <ProtectedRoute>
                <Suspense fallback={<FallbackLoading />}>
                  <SilboImageAssistant />
                </Suspense>
              </ProtectedRoute>
            }
            path="/images/:id"
          />
          <Route
            element={
              <ProtectedRoute>
                <Suspense fallback={<FallbackLoading />}>
                  <SilboTextAssistant />
                </Suspense>
              </ProtectedRoute>
            }
            path="/general"
          />
          <Route
            element={
              <ProtectedRoute>
                <Suspense fallback={<FallbackLoading />}>
                  <SilboTextAssistant />
                </Suspense>
              </ProtectedRoute>
            }
            path="/general/:id"
          />
          <Route
            element={
              <ProtectedRoute>
                <Suspense fallback={<FallbackLoading />}>
                  <SilboAdCreatorAssistant />
                </Suspense>
              </ProtectedRoute>
            }
            path="/ad_creator"
          />
          <Route element={<Login />} path="/login" />
        </Routes>

        <MahouExtraRoutes />
        <SecuritasExtraRoutes />

        <FundadorExtraRoutes />

        <ToastContainer
          autoClose={1000}
          closeButton={false}
          position="bottom-right"
          rtl
          theme="colored"
          transition={Slide}
        />
      </section>

      <LowWidthPopup />

      <footer className="silbo-footer">
        <div>
          <a
            href="https://www.iubenda.com/terms-and-conditions/24840020"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "white" }}
            target="_blank">
            {t("terms_and_conditions")}
          </a>
        </div>
        <div>
          <a
            href="https://superreal.io"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "white" }}
            target="_blank">
            {t("powered_by")}
          </a>
        </div>
      </footer>
    </main>
  );
};

export default App;
