import { useTranslation } from "react-i18next";

import LowWidthPopupImage from "@/assets/img/low-width-window.png";

import "./LowWidthPopup.css";

const LowWidthPopup = () => {
  const { t } = useTranslation();

  return (
    <div className="low-width-popup-container">
      <img
        alt="reference"
        className="low-width-popup-image"
        src={LowWidthPopupImage}
      />
      <h2 className="low-width-popup-title">{t("popup_title")}</h2>
      <p className="low-width-popup-text">{t("popup_message")}</p>
    </div>
  );
};
export default LowWidthPopup;
