import { useDispatch } from "react-redux";

import { setProfile } from "@/redux/store";

import { SILBO_PROFILES, isMobile } from "@/utils";

export const Configuration = () => {
  const dispatch = useDispatch();
  // const profile = useSelector((state) => state.main.profile)
  // const model = useSelector((state) => state.main.model)

  const onProfileChange = (profile) => {
    dispatch(setProfile(profile));
  };

  // const onModelChange = (model) => {
  //   dispatch(setModel(model));
  // }

  if (isMobile()) {
    return (
      <select
        aria-label="Default select example"
        className="mt-2 form-select"
        onChange={(e) => onProfileChange(e.target.value)}>
        <option value={SILBO_PROFILES.SILBO_BRAND_SPECIALIST}>General</option>
        <option value={SILBO_PROFILES.SILBO_SEO_SPECIALIST}>SEO Specialist</option>
        <option value={SILBO_PROFILES.SILBO_AD_COPYWRITER}>Ad Copywriter</option>
        <option value={SILBO_PROFILES.SILBO_COMMUNITY}>Community</option>
      </select>
    );
  }
  return null;
  // return <>
  //         <div className='fw-bold text-start mb-2'>{t('available_characters')}:</div>
  //         <select className="mt-2form-select" aria-label="Default select example"
  //           onChange={(e) => onProfileChange(e.target.value)}>
  //           <option value={PROFILE.GENERAL}>General</option>
  //           <option value={PROFILE.SEO_SPECIALIST}>SEO Specialist</option>
  //           <option value={PROFILE.AD_COPYWRITER}>Ad Copywriter</option>
  //           <option value={PROFILE.COMMUNITY}>Community</option>
  //         </select>

  //         <div className='fw-bold text-start mt-3'>{t('trained_models')}:</div>
  //         <div className="form-check mt-2" onClick={() => onSetModel(MODEL.GPT4)}>
  //           <input className="form-check-input silbo-radio" type="radio" name="assitant"
  //             checked={model === MODEL.GPT4} onChange={() => {}}/>
  //           <label role='button' className="form-check-label"
  //             >{t('assitant_gpt4')}</label>
  //         </div>
  //         <div className="form-check" onClick={() => onSetModel(MODEL.GPT35)}>
  //           <input className="form-check-input silbo-radio" type="radio" name="assitant"
  //             checked={model === MODEL.GPT35}  onChange={() => {}}/>
  //           <label role='button' className="form-check-label">
  //             {t('assitant_gpt35')}
  //           </label>
  //         </div>

  //       </>
};
