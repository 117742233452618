import { FUNDADOR_PRODUCTS } from "@/utils";

export const fundadorTranslationEs = {
  translation: {
    app_name: "FUNDADOR",
    your_message: "Tu mensaje",
    silbo_title_general: "FUNDADOR CopyWriter",
    silbo_title_seo_specialist: "FUNDADOR SEO Specialist GPT",
    silbo_title_ad_copywriter: "FUNDADOR Ad Copywriter GPT",
    silbo_title_community: "FUNDADOR Community Manager GPT",
    silbo_title_ad_creator: "Ad Creator",
    by_superreal: "by superreal.io",
    silbo_telecom_description_general:
      "Fundador CopyWriter es tu guía experto en todo Fundador. Como guardián de nuestra marca, lleva la antorcha de nuestra promesa: innovación y conexión de calidad. Pregúntale y descubre el núcleo de Fundador, donde cada respuesta esta en sintonía con su lema: Fundador, suena bien.",
    silbo_telecom_description_seo_specialist:
      "¡Sintoniza tu estrategia SEO con la melodía de Fundador. Descubre cómo afinar tus metadatos y enlaces para una sinfonía perfecta en los rankings 🎶!",
    silbo_telecom_description_ad_copywriter:
      "¡Dale vida a tus anuncios Display con el ritmo vibrante de Fundador! Aprende a orquestar tus visuales y mensajes para que cada anuncio sea una obra maestra visual que cautive en la galería de la web 🌈",
    silbo_telecom_description_community:
      "¡Haz que tu comunidad digital vibre al ritmo de Fundador! Aprende a dirigir las conversaciones, armonizar las interacciones y amplificar la voz de tu marca en el gran concierto de las redes sociales 📣.",
    silbo_telecom_description_ad_creator:
      "¡Dale vida a tus anuncios con el ritmo vibrante de Fundador! Con Fundador Ad Creator podrás generar creatividades para tus campañas en cuestión de minutos gracias a la IA entrenada en los mensajes de nuestra marca y su universo visual.",
    terms_and_conditions: "Terms & Conditions",
    powered_by: "Powered by: Superreal© 2024 ",
    placeholder: "Manda tu mensaje para Fundador Telecom GPT ...",
    placeholder_mobile: "Escribe a Fundador Telecom GPT...",
    available_characters: "Personajes disponibles",
    trained_models: "Modelos entrenados",
    assitant_gpt4: "Asistente GPT4",
    assitant_gpt35: "Asistente GPT3.5",
    Category: "Categoría",
    model: "Modelo",
    use_this_prompt: "Usar este Prompt",

    message_positive_placeholder_silby:
      "Describe la escena en la que quieras ver al personaje aquí…",
    message_negative_placeholder:
      "Describe elementos que no quieras que aparezcan en la imagen generada...",
    message_positive_placeholder_users:
      "Describe la escena de la temática Particulares que quieras ver aquí…",
    message_positive_placeholder_companies:
      "Describe la escena de la temática Empresas que quieras ver aquí…",
    message_positive_placeholder_copywriter:
      "Manda tu mensaje para FUNDADOR CopyWriter GPT...",

    [FUNDADOR_PRODUCTS.FUNDADOR_COPYWRITER]: "FUNDADOR Copywriter",
    [FUNDADOR_PRODUCTS.FUNDADOR_IMAGES]: "FUNDADOR Images",
    [FUNDADOR_PRODUCTS.FUNDADOR_ADCREATOR]: "FUNDADOR Ad Creator",

    gpt4: "GPT 4.0",
    gpt35: "GPT 3.5",
    stable_difusion: "Silby",
    users_model: "Particulares",
    companies_model: "Empresas",
    stable_difusion_model_description: "Embajador de marca",
    users_model_description: "Lifestyle particulares",
    companies_model_description: "Lifestyle negocios",
    popup_title: "¡Tu explorador es muy pequeño!",
    popup_message:
      "Para sacar el máximo partido a tu experiencia utiliza un laptop o PC o redimensiona tu buscador al menos con 1280px de ancho",

    // HTML
    suggestion_1_general: "<b>Crea un artículo</b> para el blog FUNDADOR",
    suggestion_2_general:
      "Genera insights sobre el <b>público objetivo FUNDADOR</b>",
    suggestion_3_general:
      "Genera frases comerciales <b>relacionadas con el claim</b>",
    suggestion_4_general:
      "Explica de forma resumida el <b>tono de comunicación FUNDADOR</b>.",

    suggestion_1_seo_specialist:
      "Cuéntame tu <b>Librería de Prompts <br>disponibles para SEO</b>",
    suggestion_2_seo_specialist:
      "Genera ideas de contenido <br>SEO <b>para el Blog Fundador</b>",
    suggestion_3_seo_specialist:
      "Silba unos <b>Metatags relevantes <br>para contenido Fundador</b>",
    suggestion_4_seo_specialist:
      "Cómo hago que mi <br><b>contenido SEO suene bien</b>",

    suggestion_1_ad_copywriter:
      "Cuéntame tu <b>Librería de Prompts <br>disponibles para Ads</b>",
    suggestion_2_ad_copywriter:
      "Escríbeme un copy 180 letras <br>para <b>un Ad que suene bien.</b>",
    suggestion_3_ad_copywriter:
      "Describe <b>3 posibles imágenes <br>para una campaña</b> de Display",
    suggestion_4_ad_copywriter:
      "Ayúdame a crear un calendario de <br><b>fechas clave para mi campaña.</b>",

    suggestion_1_community:
      "Cuéntame tu <b>Librería de Prompts <br>disponibles para Social Media</b>",
    suggestion_2_community:
      "Cómo hago que mi <b>Social <br>Media content suene bien</b>",
    suggestion_3_community:
      "Prepara la descripción de <b>un <br>Meme Fundadorástico</b>",
    suggestion_4_community:
      "Silba una encuesta para <br>un <b>Instagram Stories</b>",

    // PLAIN
    suggestion_1_general_plain: "Crea un artículo para el blog FUNDADOR",
    suggestion_2_general_plain:
      "Genera insights sobre el público objetivo FUNDADOR",
    suggestion_3_general_plain:
      "Genera frases comerciales relacionadas con el claim",
    suggestion_4_general_plain:
      "Explica de forma resumida el tono de comunicación FUNDADOR",

    suggestion_1_seo_specialist_plain:
      "Cuéntame tu Librería de Prompts disponibles para SEO",
    suggestion_2_seo_specialist_plain:
      "Genera ideas de contenido SEO para el Blog Fundador",
    suggestion_3_seo_specialist_plain:
      "Silba unos Metatags relevantes para contenido Fundador",
    suggestion_4_seo_specialist_plain:
      "Cómo hago que mi contenido SEO suene bien",

    suggestion_1_ad_copywriter_plain:
      "Cuéntame tu Librería de Prompts disponibles para Ads",
    suggestion_2_ad_copywriter_plain:
      "Escríbeme un copy 180 letras para un Ad que suene bien.",
    suggestion_3_ad_copywriter_plain:
      "Describe 3 posibles imágenes para una campaña de Display",
    suggestion_4_ad_copywriter_plain:
      "Ayúdame a crear un calendario de fechas clave para mi campaña.",

    suggestion_1_community_plain:
      "Cuéntame tu Librería de Prompts disponibles para Social Media",
    suggestion_2_community_plain:
      "Cómo hago que mi Social Media content suene bien",
    suggestion_3_community_plain:
      "Prepara la descripción de un Meme Fundadorástico",
    suggestion_4_community_plain:
      "Silba una encuesta para un Instagram Stories",

    // Loading
    loading_general:
      "Comprometidos con la calidad, estamos puliendo tu conexión Fundador. ¡Pronto sonará bien!",
    loading_seo_specialist:
      "Optimizando las claves de tu éxito SEO con Fundador. Pronto, tu contenido se elevará a las primeras posiciones en los motores de búsqueda.",
    loading_ad_copywriter:
      "Dando los últimos retoques a tu mensaje magistral. Con Fundador, cada palabra cuenta para crear impacto. ¡Espera un momento más para la revelación!",
    loading_community:
      "Preparando el escenario para diálogos memorables. Fundador está afinando la voz de tu marca para encantar en cada interacción social.",

    // Loading overlay text
    loading_overlay_text_01_general:
      "A la vanguardia de GPT-4, aunque si es un sprint, GPT-3.5 entra al rescate con ese toque 'Fundador' que buscamos. Prueba a usar el GPT 3.5",
    loading_overlay_text_02_general:
      "Si esperas un texto extenso, aprovecha para recargar energías. Al volver, encontrarás un mensaje que se hace eco de como 'Fundador, suena bien' en las redes.",
    loading_overlay_text_03_general:
      "Responder a un '¡Hola!' es instintivo, pero cuando se trata de reflejar la excelencia de Fundador, afinamos nuestras herramientas creativas para entregarte lo mejor, \"We are working on it\" ",
    loading_overlay_text_04_general:
      "Si estás perfeccionando tu estrategia SEO... Tienes tiempo para un café. Al regresar, tu SEO silbará en el Index de Google.",
    loading_overlay_text_05_general:
      "Recarga tus baterías y deja que Fundador GPT se encargue del resto. Al regresar, descubrirás que hemos transformado tu visión en un mensaje resonante que capta la esencia de 'Fundador, suena bien' en cada palabra.",
    loading_overlay_text_06_general:
      "Apostando por la elegancia de GPT-4, pero no te preocupes, GPT-3.5 está en el banquillo, listo para entrar y darle ese giro 'Fundador'. Es como tener un equipo de superhéroes en tu esquina, solo que con más teclas y menos capas",
    loading_overlay_text_07_general:
      "Si creías que no podías esperar, piénsalo de nuevo. Estamos canalizando el poder de GPT para crear algo que no solo suene bien, sino que además 'suene increíble'.",
    loading_overlay_text_08_general:
      "Recargando, ¿eh? Aquí en Fundador, estamos convirtiendo tu breve descanso en una oportunidad para infundir magia Fundador en cada palabra. Piensa en esto como en dejarnos hacer una obra de arte mientras tú tomas aire.",
    loading_overlay_text_09_general:
      "¿Descansando mientras el GPT funciona? ¿verdad? En Fundador, estamos aprovechando este instante para que el Ad Copywriter componga el mejor anuncio para tí.",
    loading_overlay_text_10_general:
      "Si la espera te parecía un ciclo sin fin, te invitamos a verlo de otra manera. Lejos de ser hámsters en una rueda, estamos utilizando Fundador GPT para crear algo no solo bueno, sino que suene bien.",
    loading_overlay_text_11_general:
      'Perfeccionando tu estrategia SEO... "¿Tiempo para un café o para ganar una carrera?" Al regresar, tu SEO estará dominando Google tan rápido, que hasta Usain Bolt querría tips de velocidad.',
    loading_overlay_text_12_general:
      "Como un vino de reserva, nuestro GPT mejora con el tiempo. A veces, necesita momentos para fermentar  plenamente. Así que, si te parece, espera un poco, el GPT te sirve la mejor añada de inteligencia artificial.",
    loading_overlay_text_13_general:
      "En el mundo del GPT, 'A buen entendedor, pocas palabras bastan'. Estamos perfeccionando cada detalle para entregarte lo mejor.",
    loading_overlay_text_14_general:
      'Este es un brillante momento para que digas: "Estoy cansado, jefe" y dejes que el GPT trabaje para ti, pero dale tiempo, cuánto tiempo tardarías tú en hacer todo lo que le has pedido?',
    loading_overlay_text_15_general:
      'En tu interior dices: "Estoy cansado, jefe", deja que Fundador GPT cocine respuestas. Imagina esperar por un plato de estrella Michelín.',

    // Loading overlay text
    loading_overlay_text_01_seo_specialist:
      "A la vanguardia de GPT-4, aunque si es un sprint, GPT-3.5 entra al rescate con ese toque 'Fundador' que buscamos. Prueba a usar el GPT 3.5",
    loading_overlay_text_02_seo_specialist:
      "Si esperas un texto extenso, aprovecha para recargar energías. Al volver, encontrarás un mensaje que se hace eco de como 'Fundador, suena bien' en las redes.",
    loading_overlay_text_03_seo_specialist:
      "Responder a un '¡Hola!' es instintivo, pero cuando se trata de reflejar la excelencia de Fundador, afinamos nuestras herramientas creativas para entregarte lo mejor, \"We are working on it\" ",
    loading_overlay_text_04_seo_specialist:
      "Si estás perfeccionando tu estrategia SEO... Tienes tiempo para un café. Al regresar, tu SEO silbará en el Index de Google.",
    loading_overlay_text_05_seo_specialist:
      "Recarga tus baterías y deja que Fundador GPT se encargue del resto. Al regresar, descubrirás que hemos transformado tu visión en un mensaje resonante que capta la esencia de 'Fundador, suena bien' en cada palabra.",
    loading_overlay_text_06_seo_specialist:
      "Apostando por la elegancia de GPT-4, pero no te preocupes, GPT-3.5 está en el banquillo, listo para entrar y darle ese giro 'Fundador'. Es como tener un equipo de superhéroes en tu esquina, solo que con más teclas y menos capas",
    loading_overlay_text_07_seo_specialist:
      "Si creías que no podías esperar, piénsalo de nuevo. Estamos canalizando el poder de GPT para crear algo que no solo suene bien, sino que además 'suene increíble'.",
    loading_overlay_text_08_seo_specialist:
      "Recargando, ¿eh? Aquí en Fundador, estamos convirtiendo tu breve descanso en una oportunidad para infundir magia Fundador en cada palabra. Piensa en esto como en dejarnos hacer una obra de arte mientras tú tomas aire.",
    loading_overlay_text_09_seo_specialist:
      "¿Descansando mientras el GPT funciona? ¿verdad? En Fundador, estamos aprovechando este instante para que el Ad Copywriter componga el mejor anuncio para tí.",
    loading_overlay_text_10_seo_specialist:
      "Si la espera te parecía un ciclo sin fin, te invitamos a verlo de otra manera. Lejos de ser hámsters en una rueda, estamos utilizando Fundador GPT para crear algo no solo bueno, sino que suene bien.",
    loading_overlay_text_11_seo_specialist:
      'Perfeccionando tu estrategia SEO... "¿Tiempo para un café o para ganar una carrera?" Al regresar, tu SEO estará dominando Google tan rápido, que hasta Usain Bolt querría tips de velocidad.',
    loading_overlay_text_12_seo_specialist:
      "Como un vino de reserva, nuestro GPT mejora con el tiempo. A veces, necesita momentos para fermentar  plenamente. Así que, si te parece, espera un poco, el GPT te sirve la mejor añada de inteligencia artificial.",
    loading_overlay_text_13_seo_specialist:
      "En el mundo del GPT, 'A buen entendedor, pocas palabras bastan'. Estamos perfeccionando cada detalle para entregarte lo mejor.",
    loading_overlay_text_14_seo_specialist:
      'Este es un brillante momento para que digas: "Estoy cansado, jefe" y dejes que el GPT trabaje para ti, pero dale tiempo, cuánto tiempo tardarías tú en hacer todo lo que le has pedido?',
    loading_overlay_text_15_seo_specialist:
      'En tu interior dices: "Estoy cansado, jefe", deja que Fundador GPT cocine respuestas. Imagina esperar por un plato de estrella Michelín.',

    // Loading overlay text
    loading_overlay_text_01_ad_copywriter:
      "A la vanguardia de GPT-4, aunque si es un sprint, GPT-3.5 entra al rescate con ese toque 'Fundador' que buscamos. Prueba a usar el GPT 3.5",
    loading_overlay_text_02_ad_copywriter:
      "Si esperas un texto extenso, aprovecha para recargar energías. Al volver, encontrarás un mensaje que se hace eco de como 'Fundador, suena bien' en las redes.",
    loading_overlay_text_03_ad_copywriter:
      "Responder a un '¡Hola!' es instintivo, pero cuando se trata de reflejar la excelencia de Fundador, afinamos nuestras herramientas creativas para entregarte lo mejor, \"We are working on it\" ",
    loading_overlay_text_04_ad_copywriter:
      "Si estás perfeccionando tu estrategia SEO... Tienes tiempo para un café. Al regresar, tu SEO silbará en el Index de Google.",
    loading_overlay_text_05_ad_copywriter:
      "Recarga tus baterías y deja que Fundador GPT se encargue del resto. Al regresar, descubrirás que hemos transformado tu visión en un mensaje resonante que capta la esencia de 'Fundador, suena bien' en cada palabra.",
    loading_overlay_text_06_ad_copywriter:
      "Apostando por la elegancia de GPT-4, pero no te preocupes, GPT-3.5 está en el banquillo, listo para entrar y darle ese giro 'Fundador'. Es como tener un equipo de superhéroes en tu esquina, solo que con más teclas y menos capas",
    loading_overlay_text_07_ad_copywriter:
      "Si creías que no podías esperar, piénsalo de nuevo. Estamos canalizando el poder de GPT para crear algo que no solo suene bien, sino que además 'suene increíble'.",
    loading_overlay_text_08_ad_copywriter:
      "Recargando, ¿eh? Aquí en Fundador, estamos convirtiendo tu breve descanso en una oportunidad para infundir magia Fundador en cada palabra. Piensa en esto como en dejarnos hacer una obra de arte mientras tú tomas aire.",
    loading_overlay_text_09_ad_copywriter:
      "¿Descansando mientras el GPT funciona? ¿verdad? En Fundador, estamos aprovechando este instante para que el Ad Copywriter componga el mejor anuncio para tí.",
    loading_overlay_text_10_ad_copywriter:
      "Si la espera te parecía un ciclo sin fin, te invitamos a verlo de otra manera. Lejos de ser hámsters en una rueda, estamos utilizando Fundador GPT para crear algo no solo bueno, sino que suene bien.",
    loading_overlay_text_11_ad_copywriter:
      'Perfeccionando tu estrategia SEO... "¿Tiempo para un café o para ganar una carrera?" Al regresar, tu SEO estará dominando Google tan rápido, que hasta Usain Bolt querría tips de velocidad.',
    loading_overlay_text_12_ad_copywriter:
      "Como un vino de reserva, nuestro GPT mejora con el tiempo. A veces, necesita momentos para fermentar  plenamente. Así que, si te parece, espera un poco, el GPT te sirve la mejor añada de inteligencia artificial.",
    loading_overlay_text_13_ad_copywriter:
      "En el mundo del GPT, 'A buen entendedor, pocas palabras bastan'. Estamos perfeccionando cada detalle para entregarte lo mejor.",
    loading_overlay_text_14_ad_copywriter:
      'Este es un brillante momento para que digas: "Estoy cansado, jefe" y dejes que el GPT trabaje para ti, pero dale tiempo, cuánto tiempo tardarías tú en hacer todo lo que le has pedido?',
    loading_overlay_text_15_ad_copywriter:
      'En tu interior dices: "Estoy cansado, jefe", deja que Fundador GPT cocine respuestas. Imagina esperar por un plato de estrella Michelín.',

    // Loading overlay text
    loading_overlay_text_01_community:
      "A la vanguardia de GPT-4, aunque si es un sprint, GPT-3.5 entra al rescate con ese toque 'Fundador' que buscamos. Prueba a usar el GPT 3.5",
    loading_overlay_text_02_community:
      "Si esperas un texto extenso, aprovecha para recargar energías. Al volver, encontrarás un mensaje que se hace eco de como 'Fundador, suena bien' en las redes.",
    loading_overlay_text_03_community:
      "Responder a un '¡Hola!' es instintivo, pero cuando se trata de reflejar la excelencia de Fundador, afinamos nuestras herramientas creativas para entregarte lo mejor, \"We are working on it\" ",
    loading_overlay_text_04_community:
      "Si estás perfeccionando tu estrategia SEO... Tienes tiempo para un café. Al regresar, tu SEO silbará en el Index de Google.",
    loading_overlay_text_05_community:
      "Recarga tus baterías y deja que Fundador GPT se encargue del resto. Al regresar, descubrirás que hemos transformado tu visión en un mensaje resonante que capta la esencia de 'Fundador, suena bien' en cada palabra.",
    loading_overlay_text_06_community:
      "Apostando por la elegancia de GPT-4, pero no te preocupes, GPT-3.5 está en el banquillo, listo para entrar y darle ese giro 'Fundador'. Es como tener un equipo de superhéroes en tu esquina, solo que con más teclas y menos capas",
    loading_overlay_text_07_community:
      "Si creías que no podías esperar, piénsalo de nuevo. Estamos canalizando el poder de GPT para crear algo que no solo suene bien, sino que además 'suene increíble'.",
    loading_overlay_text_08_community:
      "Recargando, ¿eh? Aquí en Fundador, estamos convirtiendo tu breve descanso en una oportunidad para infundir magia Fundador en cada palabra. Piensa en esto como en dejarnos hacer una obra de arte mientras tú tomas aire.",
    loading_overlay_text_09_community:
      "¿Descansando mientras el GPT funciona? ¿verdad? En Fundador, estamos aprovechando este instante para que el Ad Copywriter componga el mejor anuncio para tí.",
    loading_overlay_text_10_community:
      "Si la espera te parecía un ciclo sin fin, te invitamos a verlo de otra manera. Lejos de ser hámsters en una rueda, estamos utilizando Fundador GPT para crear algo no solo bueno, sino que suene bien.",
    loading_overlay_text_11_community:
      'Perfeccionando tu estrategia SEO... "¿Tiempo para un café o para ganar una carrera?" Al regresar, tu SEO estará dominando Google tan rápido, que hasta Usain Bolt querría tips de velocidad.',
    loading_overlay_text_12_community:
      "Como un vino de reserva, nuestro GPT mejora con el tiempo. A veces, necesita momentos para fermentar  plenamente. Así que, si te parece, espera un poco, el GPT te sirve la mejor añada de inteligencia artificial.",
    loading_overlay_text_13_community:
      "En el mundo del GPT, 'A buen entendedor, pocas palabras bastan'. Estamos perfeccionando cada detalle para entregarte lo mejor.",
    loading_overlay_text_14_community:
      'Este es un brillante momento para que digas: "Estoy cansado, jefe" y dejes que el GPT trabaje para ti, pero dale tiempo, cuánto tiempo tardarías tú en hacer todo lo que le has pedido?',
    loading_overlay_text_15_community:
      'En tu interior dices: "Estoy cansado, jefe", deja que Fundador GPT cocine respuestas. Imagina esperar por un plato de estrella Michelín.',

    // Loading overlay text
    loading_overlay_text_01_silby:
      "Mientras Silby se prepara para aparecer en tu pantalla, ¿por qué no te relajas y sueñas con conexiones perfectas? Recuerda, Fundador no solo suena bien, se ve increíble.",
    loading_overlay_text_02_silby:
      "Silby está en modo 'genio creativo'. Estamos transformando su imagen de buena a extraordinaria. ¡Sólo un poco más de paciencia!",
    loading_overlay_text_03_silby:
      "Piensa en esto como el intervalo antes del gran espectáculo. Silby está detrás del telón preparándose para sorprenderte. ¡Prepárate para aplaudir!",
    loading_overlay_text_04_silby:
      "¿Sabías que incluso Da Vinci tomaba su tiempo? Estamos usando estos momentos para asegurar que cada imagen de Silby sea un verdadero Renacimiento.",
    loading_overlay_text_05_silby:
      "Escucha, ¿puedes oírlo? Es Silby tarareando una melodía, casi como si Picasso estuviera preparando su paleta. Cada proceso suena bien, y el resultado final es aún mejor.",
    loading_overlay_text_06_silby:
      "En Fundador usamos Stable Diffusion para transformar tus imágenes de simples a espectaculares. ¡Paciencia, que la magia está en proceso!",
    loading_overlay_text_07_silby:
      "Mientras Silby se prepara para aparecer en tu pantalla, ¿por qué no te relajas y sueñas con conexiones perfectas? Recuerda, Fundador no solo suena bien, se ve increíble.",
    loading_overlay_text_08_silby:
      "Silby está en modo 'genio creativo'. Estamos transformando su imagen de buena a extraordinaria. ¡Sólo un poco más de paciencia!",
    loading_overlay_text_09_silby:
      "Piensa en esto como el intervalo antes del gran espectáculo. Silby está detrás del telón preparándose para sorprenderte. ¡Prepárate para aplaudir!",
    loading_overlay_text_10_silby:
      "¿Sabías que incluso Da Vinci tomaba su tiempo? Estamos usando estos momentos para asegurar que cada imagen de Silby sea un verdadero Renacimiento.",
    loading_overlay_text_11_silby:
      "Escucha, ¿puedes oírlo? Es Silby tarareando una melodía, casi como si Picasso estuviera preparando su paleta. Cada proceso suena bien, y el resultado final es aún mejor.",
    loading_overlay_text_12_silby:
      "En Fundador usamos Stable Diffusion para transformar tus imágenes de simples a espectaculares. ¡Paciencia, que la magia está en proceso!",
    loading_overlay_text_13_silby:
      "Silby está en modo 'genio creativo'. Estamos transformando su imagen de buena a extraordinaria. ¡Sólo un poco más de paciencia!",
    loading_overlay_text_14_silby:
      "Piensa en esto como el intervalo antes del gran espectáculo. Silby está detrás del telón preparándose para sorprenderte. ¡Prepárate para aplaudir!",
    loading_overlay_text_15_silby:
      "¿Sabías que incluso Da Vinci tomaba su tiempo? Estamos usando estos momentos para asegurar que cada imagen de Silby sea un verdadero Renacimiento.",

    // HOME
    home_title: "Bienvenid@",
    "main-content-title": "FUNDADOR Ad Creator",
    "main-content-text":
      "FUNDADOR GPT es el nuevo asistente de marketing entrenado en nuestra marca y nuestros productos capaz de generar textos, imágenes y campañas de una forma más rápida, más eficiente y más consistente",
    "main-content-button": "Tutorial",
    home_secondary_title: "Aplicaciones FUNDADOR GPT:",
    home_secondary_subtitle:
      "Explora los modelos entrenados para la generación de imagen y texto en los diferentes escenarios de nuestra marca.",

    update_password_title: "Actualizar contraseña",
    update_password_subtitle:
      "Introduce la contraseña actual para validar que eres tú.",
    ["home-feedback"]: "¡Danos tu feedback!",

    landing_extra_text_1: "BANCO DE IMÁGENES",
    landing_title_1: "Personajes FUNDADOR",
    landing_text_1:
      "Crea vibrantes imágenes de personajes atrevidos, modernos y con un estilo único. Cada imagen capturará la actitud de aquellos que disfrutan la vida con pasión y curiosidad, invitándote a descubrir nuevas experiencias.",

    landing_extra_text_2: "BANCO DE IMÁGENES",
    landing_title_2: "Momento de Consumo",
    landing_text_2:
      "Genera una colección visual de los momentos de consumo más icónicos de Fundador. Desde reuniones íntimas hasta grandes celebraciones, estas imágenes te ayudarán a capturar la versatilidad y sofisticación de Fundador en cada ocasión. Aquí, la tradición se encuentra con la modernidad, brindando inspiración para disfrutar de una bebida que transforma cualquier momento en una celebración.",

    // Character description
    general_description: "Experto en Marca FUNDADOR",
    seo_specialist_description: "Redactor y estratega SEO",
    ad_copywriter_description: "Creativo de Camapañas",
    community_description: "Creador de campañas",
    ad_creator_description: "Creativo de Campañas",
    silby_description: "Imágenes bajo demanda",

    product: "Producto",

    // Model description
    personajes: "Personajes",
    personajes_image: "Imágenes de Personajes",
    momentos: "Momentos",
    personajes_momentos: "Imágenes de Momentos",
    gpt_4_description: "El más potente",
    gpt_3_5_description: "El más rápido",
    stable_difusion_description: "Version beta v1",

    model_silby_title: "Imágenes Silby",
    model_users_title: "Imágenes Particulares",
    model_business_title: "Imágenes Empresas",

    include: "Incluye",
    exclude: "Excluye",
    lifestyle: "Fotografía Lifestyle",
    alto_detalle: "Alto detalle",
    bodegon: "Bodegón",

    // Ad Creator
    start: "Empezar",
    define_your_client: "Primero, define tu cliente:",
    generic: "Silby",
    particulars: "Particulares",
    companies: "Empresas",
    step_3_briefing_title: "Recogida de briefing",
    step_4_copies_title: "Generación de Copies",
    step_5_visuals_title: "Generación de Visuales",
    step_6_creativities_title: "Generación de Creatividades",
    step_7_export_title: "Guardado y Exportación",
    product_service: "Producto/Servicio",
    placeholder_product_service: "Ej: Fibra ilimitada 300mb",
    audience: "Audiencia",
    price_per_month: "Precio/mes",
    destination_url: "URL",
    placeholder_audience: "Ej: Estudiantes...",
    placeholder_price: "Ej: 15",
    placeholder_url: "Ej: https://silbotelecom.com/tarifas-fibra/",
    additional_information: "Información adicional",
    placeholder_additional_information: "Ej: Solo durante el mes de Julio",
    generate: "Generar",
    campaign_objective: "Objetivo de la campaña",
    option_awareness: "Awareness",
    option_engagement: "Engagement",
    option_conversion: "Conversión",

    step_3_briefing_large_title: "Recogida de briefing",
    step_4_copies_mid_title: "¡Hecho!",
    step_4_copies_mid_large:
      "En base a tu briefing, hemos detectado los siguientes insights sobre los que empezar a trabajar tu campaña. ",
    step_4_copies_mid_large_title: "¡Perfecto!",
    step_4_copies_large_title:
      "En base a tu briefing y el insight seleccionado, te proponemos los siguientes copies para tu creatividad:",
    step_5_visuals_large_title:
      "¿Cuál te gusta más? ¡Siempre puedes volver a generar!",
    step_6_creativities_large_title:
      "Aquí tienes las dos versiones principales para tu aprobación:",
    step_7_export_large_title: "Guardado y Exportación",

    step_1_loading: "Espera mientras preparamos todo  ...",
    step_3_loading: "Analizando tu briefing ...",
    step_4_copies_loading: "Generando conceptos creativos ...",
    step_4_copies_ellaborate_loading: "Produciendo 2 opciones de visual ...",
    step_5_visuals_loading: "Construyendo key visual ...",
    step_7_export_loading: "Generando creatividades finales ...",

    main_copy: "Copy Principal",
    visual_narrative: "Narrativa Visual",
    back: "Volver al paso anterior",
    horizontal_format: "Formato Horizontal",
    vertical_format: "Formato Vertical",
    select: "Seleccionar",
    approve: "Aprobar",
    download: "Download",
    congratulations: "¡Enhorabuena!",
    congratulations_description:
      "Tu set de creatividades ya se está descargando.Mientras tanto, si quieres generar otro nuevo set, ¡puedes volver a generar!",
    restart: "Volver a generar",
  },
};
