import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import fundadorLoadingLogo from "@/assets/img/Fundador_loading.svg";
import securitasLoadingLogo from "@/assets/img/Loading_SD.png";
import mahouLoadingLogo from "@/assets/img/Loading_mahou.png";
import silboLoadingLogo from "@/assets/img/silbo_loading_logo.gif";

import { useTimeout } from "@/hooks/useTimeout";

import {
  AD_CREATOR_STEP,
  setLoadingTime,
  setLoadingV2,
  stepOrder,
  setLoadingText as setLoadingTextStore
} from "@/redux/store";

import theme from "@/branding/theme";

import "./LoadingOverlay.css";

const LoadingOverlayV2 = () => {
  const { t } = useTranslation();
  const [transitionStart, setTransitionStart] = useState(false);
  const adCreatorStepState = useSelector((state) => state.adCreator.step);
  const loadingTime = useSelector((state) => state.main.loadingTime);
  const loadingTextState = useSelector((state) => state.main.loadingText);
  const isForRegeneration = useSelector(
    (state) => state.main.isForRegeneration,
  );
  const stepNumber = useSelector((state) => state.adCreator.stepNumber);
  const [loadingText, setLoadingText] = useState("");
  const dispatch = useDispatch();

  const MAX_PROGRESS = 100; // max  [%]
  const STEP_PROGRESS = 1; // step [%]
  const [progress, setProgress] = useState(10); // current progress [%]

  // Compute secs per step
  const N_STEPS = (MAX_PROGRESS - 10) / STEP_PROGRESS;
  const secsPerStep = progress < MAX_PROGRESS ? loadingTime / N_STEPS : null;

  const adCreatorStep = isForRegeneration
    ? stepOrder[stepNumber - 1]
    : adCreatorStepState;

  useEffect(() => {
    setTransitionStart(true);

    // Compute text
    if (loadingTextState) {
      setLoadingText(loadingTextState);
    } else {
      if (adCreatorStep === AD_CREATOR_STEP.STEP_1_WELCOME)
        setLoadingText(t("step_1_loading"));
      if (adCreatorStep === AD_CREATOR_STEP.STEP_3_BRIEFING)
        setLoadingText(t("step_3_loading"));
      if (adCreatorStep === AD_CREATOR_STEP.STEP_4_COPIES)
        setLoadingText("step_4_copies_loading");
      if (adCreatorStep === AD_CREATOR_STEP.STEP_4_COPIES_ELABORATE)
        setLoadingText(t("step_4_copies_ellaborate_loading"));
      if (adCreatorStep === AD_CREATOR_STEP.STEP_5_VISUALS)
        setLoadingText("step_5_visuals_loading");
      if (adCreatorStep === AD_CREATOR_STEP.STEP_6_CREATIVITIES)
        setLoadingText(t("step_7_export_loading"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTextState]);

  useTimeout(
    () => {
      setProgress(progress + STEP_PROGRESS);
      if (progress + STEP_PROGRESS >= 100) {
        // Give a little bit for the user to see that this reached 100%
        setTimeout(() => dispatch(setLoadingV2(false)), 1000);
      }
    },
    secsPerStep,
    progress,
  );

  useEffect(() => {
    if (progress >= 100) {
      dispatch(setLoadingTime(7 * 60 * 1000));
      dispatch(setLoadingTextStore(undefined));
    }
  }, [dispatch, progress]);

  const imageLoading =
    theme().login.case === "Fundador"
      ? fundadorLoadingLogo
      : theme().login.case === "Mahou"
        ? mahouLoadingLogo
        : theme().login.case === "Securitas"
          ? securitasLoadingLogo
          : silboLoadingLogo;
  return (
    <div className={`silbo-loading-overlay ${transitionStart ? "show" : ""}`}>
      <div className="container d-flex align-items-center w-100 h-100 flex-column">
        <div
          className="text-center content"
          style={{
            minWidth: "60%",
            marginTop: "3rem",
          }}>
          <img alt="" src={imageLoading} />
          <div
            className="position-relative w-100 loading-bar"
            style={{
              height: "25px",
              borderRadius: "20px",
              marginTop: "5rem",
            }}>
            <div
              className="loaded-bar position-absolute "
              style={{
                height: "25px",
                borderRadius: "20px",
                width: `${progress}%`,
              }}
            />
          </div>
          <div
            className="mt-5"
            style={{
              color:
                theme().login.case != "Silbo" ? "var(--main-color)" : undefined,
            }}>
            {progress}%
          </div>
          <div
            style={{
              color:
                theme().login.case != "Silbo" ? "var(--main-color)" : undefined,
            }}>
            {loadingText}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingOverlayV2;
