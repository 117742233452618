import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import HeaderDesktop from "@/components/Header/Desktop";
import HeaderMobile from "@/components/Header/Mobile";

import theme from "@/branding/theme";
import { isMobile } from "@/utils";

import "./Header.css";

export const Header = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("app_name")}</title>
        <link href={theme().ico} rel="icon" />
      </Helmet>
      {isMobile() ? <HeaderMobile {...props} /> : <HeaderDesktop {...props} />}
    </>
  );
};
