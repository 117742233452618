import { createSlice } from "@reduxjs/toolkit";

import { minWidthValue } from "@/utils/sidebar";

import { SILBO_MODELS, SILBO_PROFILES } from "@/utils";

export const LOADING_V2_MODE = {
  SLOW: "SLOW",
  FAST: "FAST",
};

const mainReducerSlice = createSlice({
  name: "main",
  initialState: {
    model: SILBO_MODELS.STABLE_DIFUSION,
    profile: undefined,
    previousProfile: SILBO_PROFILES.SILBO_BRAND_SPECIALIST,
    loading: false,
    loadingV2: false,
    isForRegeneration: false,
    loadingV2Mode: LOADING_V2_MODE.SLOW,
    loadingTime: 7 * 60 * 1000,
    loadingText: undefined,
    fakeMode: false,
    intervalPeriodForTask: 10 * 1000,
    session: undefined,
    sidebarMaxWidth: minWidthValue,
  },
  reducers: {
    setModel: (state, action) => {
      state.model = action.payload;
    },
    setProfile: (state, action) => {
      state.previousProfile = state.profile;
      state.profile = action.payload;
      if (!state.model) state.model = SILBO_MODELS.STABLE_DIFUSION;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingV2: (state, action) => {
      state.loadingV2Mode = LOADING_V2_MODE.SLOW;
      state.loadingV2 = action.payload;
    },
    setLoadingV2Mode: (state, action) => {
      if (action.payload === LOADING_V2_MODE.FAST) {
        state.loadingTime = 1300;
      }
    },
    setIntervalPeriodForTask: (state, action) => {
      state.intervalPeriodForTask = action.payload;
    },
    setFakeMode: (state, action) => {
      state.fakeMode = action.payload;
    },
    setIsForRegeneration: (state, action) => {
      state.isForRegeneration = action.payload;
    },
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setMaxWidth: (state, action) => {
      state.sidebarMaxWidth = action.payload;
    },
    setLoadingTime: (state, action) => {
      state.loadingTime = action.payload;
    },
    setLoadingText: (state, action) => {
      state.loadingText = action.payload;
    },
  },
});

export const AD_CREATOR_STEP = {
  STEP_1_WELCOME: "STEP_1_WELCOME",
  STEP_2_PROFILE: "STEP_2_PROFILE",
  STEP_3_BRIEFING: "STEP_3_BRIEFING",
  STEP_4_COPIES: "STEP_4_COPIES",
  STEP_4_COPIES_ELABORATE: "STEP_4_COPIES_ELABORATE",
  STEP_5_VISUALS: "STEP_5_VISUALS",
  STEP_6_CREATIVITIES: "STEP_6_CREATIVITIES",
  STEP_7_EXPORT: "STEP_7_EXPORT",
  STEP_7_THANKYOU: "STEP_7_THANKYOU",
};

export const stepOrder = [
  AD_CREATOR_STEP.STEP_1_WELCOME,
  AD_CREATOR_STEP.STEP_2_PROFILE,
  AD_CREATOR_STEP.STEP_3_BRIEFING,
  AD_CREATOR_STEP.STEP_4_COPIES,
  AD_CREATOR_STEP.STEP_4_COPIES_ELABORATE,
  AD_CREATOR_STEP.STEP_5_VISUALS,
  AD_CREATOR_STEP.STEP_6_CREATIVITIES,
  AD_CREATOR_STEP.STEP_7_EXPORT,
  AD_CREATOR_STEP.STEP_7_THANKYOU,
];
const adCretorReducerSlice = createSlice({
  name: "adCretor",
  initialState: {
    step: AD_CREATOR_STEP.STEP_1_WELCOME,
    stepNumber: 0,
    isMid: false,
    session: null,

    data: {
      profile: undefined,
      briefing: {
        product: "",
        audience: "",
        campaign_url: "",
        additional_info: "",
        ad_objective: "",
        price: "",
      },
      insight_list: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
      ],
      insight_selected:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
      elaborate_insight_list: [
        {
          copy_principal: "Una prueba es una prueba",
          info_visual: "genérico",
          copy_cta: "silba aquí",
        },
        {
          copy_principal: "Una prueba es una prueba",
          info_visual: "genérico",
          copy_cta: "silba aquí",
        },
        {
          copy_principal: "Una prueba es una prueba",
          info_visual: "genérico",
          copy_cta: "silba aquí",
        },
      ],
      elaborate_insight_selected: {
        copy_principal: "Una prueba es una prueba",
        info_visual: "genérico",
        copy_cta: "silba aquí",
      },
      visual_image_list: [],
      visual_image_selected: null,
      download_urls: [],
      download_urls_final: [],
      zip: null,
    },
  },
  reducers: {
    setAdCreatorStep: (state, action) => {
      state.stepNumber = stepOrder.findIndex((item) => item === action.payload);
      state.step = action.payload;
    },
    setAdCreatorStepNext: (state, action) => {
      state.stepNumber = state.stepNumber + 1;
      state.isMid =
        action.payload != null && action.payload.isMid != null
          ? action.payload.isMid
          : false;
      state.step = stepOrder[state.stepNumber];
    },
    setAdCreatorStepPrevious: (state) => {
      state.stepNumber = state.stepNumber - 1;
      state.step = stepOrder[state.stepNumber];
    },
    setAdCreatorSession: (state, action) => {
      state.session = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  setModel,
  setProfile,
  setLoading,
  setLoadingV2,
  setLoadingV2Mode,
  setIntervalPeriodForTask,
  setFakeMode,
  setIsForRegeneration,
  setSession,
  setMaxWidth,
  setLoadingTime,
  setLoadingText,
} = mainReducerSlice.actions;
export const {
  setAdCreatorStep,
  setAdCreatorStepNext,
  setAdCreatorStepPrevious,
  setAdCreatorSession,
  setData,
} = adCretorReducerSlice.actions;

export const mainReducer = mainReducerSlice.reducer;
export const adCretorReducer = adCretorReducerSlice.reducer;
