import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect } from "react";

import { ReactComponent as IaForBrands } from "@/assets/img/ia-for-brands.svg";

import ExpandableMenu from "@/components/Header/ExpandeableMenu";

import {
  LOADING_V2_MODE,
  setLoadingV2,
  setLoadingV2Mode,
  setModel,
  setProfile,
  setSession,
} from "@/redux/store";

import theme from "@/branding/theme";

const HeaderDesktop = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.main.profile);
  const model = useSelector((state) => state.main.model);
  const navigate = useNavigate();
  const location = useLocation();

  const selectedProfile = profile
    ? theme().appBarProfiles.profileList.find((p) => p.value === profile)
    : undefined;

  const onProfileChange = (profileClicked) => {
    const clickedProfile = theme().appBarProfiles.profileList.find(
      (p) => p.value === profileClicked,
    );
    dispatch(setProfile(clickedProfile ? clickedProfile.value : undefined));
    navigate(clickedProfile.path, { replace: true });
  };

  const onModelChange = (model) => {
    dispatch(setModel(model));
    const clickedModel = selectedProfile.subItems.find(
      (m) => m.value === model,
    );
    if (clickedModel && clickedModel.path !== location.pathname) {
      dispatch(setModel(model));
      navigate(clickedModel.path, { replace: true });
    }
  };

  useEffect(() => {
    if (!profile) {
      const profilePath = location.pathname;
      const pathProfile = theme().appBarProfiles.profileList.find((p) =>
        profilePath.startsWith(p.path),
      );
      dispatch(setProfile(pathProfile ? pathProfile.value : undefined));
    }
  }, [dispatch, location.pathname, profile]);

  if (location.pathname === "/login") return null;

  return (
    <header
      className={`silbo-header ${location.pathname.startsWith("/home/") ? "landing" : ""}`}
      style={{
        color: location.pathname.startsWith("/home/") ? "white" : undefined,
      }}>
      <div className="d-flex align-items-center py-3">
        <div
          onClick={() => {
            dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
            dispatch(setLoadingV2(false));
            navigate("/", { replace: true });
          }}
          role="button">
          <img
            alt=""
            className="app-logo-image"
            src={theme().home.appLogo.default}
            style={{ marginBottom: "0.5rem" }}
          />
          <span
            className="ms-2"
            style={{ lineHeight: "25px", fontSize: "20px", fontWeight: 700 }}>
            {t("app_name")}
          </span>
        </div>
        {location.pathname !== "/" && location.pathname !== "/login" ? (
          <div className="px-3 d-flex gap-3">
            <ExpandableMenu
              headerTranslation="product"
              itemList={theme().appBarProfiles.profileList}
              onChange={onProfileChange}
              text={selectedProfile ? selectedProfile.name : undefined}
              value={selectedProfile}
            />
            {selectedProfile && selectedProfile.subItems ? (
              <ExpandableMenu
                headerTranslation="model"
                itemList={selectedProfile.subItems}
                onChange={(e) => {
                  dispatch(setSession(undefined));
                  onModelChange(e);
                }}
                showInput={true}
                value={model}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <div
        className="d-flex align-items-center justify-content-center h-100 fw-bold"
        onClick={() =>
          window.open("https://superreal.io/gen-ia-4-marketing", "_blank")
        }
        role="button"
        style={{ padding: "0.5em 0 0.5em 0" }}>
        <IaForBrands />
      </div>
    </header>
  );
};
export default HeaderDesktop;
