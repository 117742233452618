const MenuItem = (props) => {
  const onClick = (e) => {
    if (props.disabled) return;
    e.stopPropagation();
    props.onClick(props.value);
  };

  const maxWidth = props.value === "ad_creator" ? "40px" : "20px";

  return (
    <div
      className={`d-flex align-items-center item p-1 gap-2 ${props.disabled ? "disabled-item" : ""}`}
      onClick={(e) => onClick(e)}
      style={{
        color: props.disabled ? "#909090" : "",
        opacity: props.disabled ? 0.5 : 1,
        pointerEvents: props.disabled ? "none" : "auto",
      }}>
      {props.icon ? (
        <div className="text-center" style={{ width: "50px" }}>
          <img alt="" src={props.icon} style={{ maxWidth }} />
        </div>
      ) : (
        <div className="px-1" />
      )}
      <div className="flex-grow-1 menu-text" style={{ fontSize: "12px" }}>
        <div className="fw-bold">{props.name}</div>
        <div style={{ color: "#909090" }}>{props.description}</div>
      </div>
      <div className="ms-3">
        {props.showInput ? (
          <input
            checked={props.value === props.selectedValue}
            className="form-check-input silbo-radio"
            readOnly
            type="radio"
          />
        ) : null}
      </div>
    </div>
  );
};

export default MenuItem;
