import { useEffect, useRef } from "react";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const interval_id = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      interval_id.current = setInterval(tick, delay);
      return () => clearInterval(interval_id.current);
    }
    if (delay == null) {
      clearInterval(interval_id.current);
    }
  }, [delay]);
};
