import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";

import { useState } from "react";

import MenuItem from "@/components/Header/MenuItem";

const ExpandableMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const onChange = (value, isDisabled) => {
    if (!isDisabled) {
      setShowMenu(false);
      props.onChange(value);
    }
  };
  return (
    <div
      className="menu-expandable d-flex justify-content-between position-relative"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      role="button">
      <span className="fw-bold">
        {props.text ? props.text : t(props.value)}
      </span>
      <div className={`angle ${showMenu ? "active" : ""}`}>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {showMenu ? (
        <div className="position-absolute silbo-shadow p-3 menu-container">
          <div className="menu-title" style={{ fontSize: "12px" }}>
            {t(props.headerTranslation ? props.headerTranslation : "character")}
          </div>
          <div>
            {props.itemList.map((item, idx) => (
              <MenuItem
                key={idx}
                {...item}
                disabled={item.disable} // Pass the disable prop
                onClick={() => onChange(item.value, item.disable)}
                selectedValue={props.value}
                showInput={props.showInput}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExpandableMenu;
