import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import AltoDetalle from "@/assets/img/securitas/Alto_Detalle.png";
import Bodegon from "@/assets/img/securitas/Bodegon.png";
import Carteles from "@/assets/img/securitas/Carteles.png";
import Edicion from "@/assets/img/securitas/Edicion.png";

import ProtectedRoute from "@/components/ProtectedRoute";

import { Landing } from "@/pages/ad_creator/Landing";

import { setModel, setProfile } from "@/redux/store";

import { MAHOU_MODELS, SECURITAS_MODELS } from "@/utils";

const SecuritasExtraRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (process.env.REACT_APP_CLIENT !== "Securitas") {
    return null;
  }
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Landing
              extraText={t("landing_extra_text_1")}
              image={AltoDetalle}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setModel(SECURITAS_MODELS.ALTO_DETALLE));
                dispatch(setProfile(SECURITAS_MODELS.ALTO_DETALLE));
              }}
              text={t("landing_text_1")}
              title={t("landing_title_1")}
            />
          </ProtectedRoute>
        }
        path="/home/alto-detalle"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              extraText={t("landing_extra_text_2")}
              image={Bodegon}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setModel(SECURITAS_MODELS.BODEGON));
                dispatch(setProfile(SECURITAS_MODELS.ALTO_DETALLE));
              }}
              text={t("landing_text_2")}
              title={t("landing_title_2")}
            />
          </ProtectedRoute>
        }
        path="/home/bodegon"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              extraText={t("landing_extra_text_3")}
              image={Carteles}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setModel(SECURITAS_MODELS.CARTELES_DISUASORIOS));
                dispatch(setProfile(SECURITAS_MODELS.ALTO_DETALLE));
              }}
              text={t("landing_text_3")}
              title={t("landing_title_3")}
            />
          </ProtectedRoute>
        }
        path="/home/carteles-disuasorios"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              extraText={t("landing_extra_text_4")}
              image={Edicion}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setModel(MAHOU_MODELS.EDITOR));
                dispatch(setProfile(SECURITAS_MODELS.ALTO_DETALLE));
              }}
              text={t("landing_text_4")}
              title={t("landing_title_4")}
            />
          </ProtectedRoute>
        }
        path="/home/edicion"
      />
    </Routes>
  );
};
export default SecuritasExtraRoutes;
