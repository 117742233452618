import { ReactComponent as IaForBrands } from "@/assets/img/ia-for-brands.svg";
import silboLogo from "@/assets/img/silbo_logo_v2.svg";

import { getProfileLiterals } from "@/utils";

const HeaderMobile = (props) => {
  const { header } = getProfileLiterals(props.profile);

  return (
    <header className="silbo-header silbo-shadow">
      <div
        className="d-flex align-items-center"
        onClick={() => window.location.reload()}
        role="button">
        <img alt="" src={silboLogo} style={{ height: "20px" }} />
        <span
          className="ms-2"
          style={{ lineHeight: "25px", fontSize: "14px", fontWeight: 700 }}>
          {header}
        </span>
      </div>
      <div
        className="d-flex align-items-center justify-content-center h-100 fw-bold"
        onClick={() =>
          window.open("https://superreal.io/gen-ia-4-marketing", "_blank")
        }
        role="button"
        style={{ padding: "0.5em 0 0.5em 0" }}>
        <IaForBrands alt="" height="21" />
      </div>
    </header>
  );
};
export default HeaderMobile;
