// Images
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";

import { setAdCreatorStepNext } from "@/redux/store";

export const Landing = ({
  onClick,
  image,
  title,
  text,
  onRender,
  extraText,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fakeMode = useSelector((state) => state.main.fakeMode);

  const handleClick = () => {
    if (fakeMode) {
      dispatch(setAdCreatorStepNext());
      return;
    }

    if (onClick) onClick();
  };

  useEffect(() => {
    if (onRender) onRender();
  }, [onRender]);

  return (
    <div className="silbo-title-container silbo-title-container-ad-creator">
      <img className="silbo-background-img" src={image} />
      <div className="silbo-background-gradient" />
      <div className="silbo-text-container">
        <div
          className="silbo-title ta-left"
          style={{ fontSize: "48px", width: "100%" }}>
          {extraText ? (
            <div
              className="silbo-extra-text"
              style={{ fontSize: "13px", paddingBottom: "2rem" }}>
              {extraText ?? t("silbo_title_ad_creator")}
            </div>
          ) : null}
          {title ?? t("silbo_title_ad_creator")}
        </div>
        <div
          className="silbo-description-ad-creator"
          style={{ fontSize: "14px" }}>
          {text ?? t("silbo_telecom_description_ad_creator")}
        </div>
        <div className="mt-3">
          <div
            className="silbo-button-ad-creator silbo-rounded text-center p-3 w-50"
            onClick={() => handleClick()}
            style={{ fontSize: "14px", margin: "0.7rem 0" }}>
            {t("start")}
          </div>
        </div>
      </div>
    </div>
  );
};
